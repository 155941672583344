<template>
  <!-- eslint-disable -->
  <b-card>
    <b-overlay
      :show="!invoices"
      rounded="sm"
    >
      <div class="invoice-table">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="invoices"
          :columns="columns"
          :rows="invoices"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :sort-options="{
            enabled: true,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'invoiceNo'">
              <router-link
                :to="`${props.row.customLinkDetail}`"
              >
                {{ props.row.customInvoiceNo }}
              </router-link>
            </span>

            <!-- Column: Package -->
            <span v-else-if="props.column.field === 'package'">
              {{ props.row.customPackage }}
            </span>

            <!-- Column: Issued On -->
            <span v-else-if="props.column.field === 'issuedOn'">
              {{ formatDate(props.row.issue_date) }}
            </span>

            <!-- Column: Value -->
            <span v-else-if="props.column.field === 'invoiceValue'">
              {{ props.row.customInvoiceValue }}
            </span>

            <!-- Column: Period -->
            <span v-else-if="props.column.field === 'period'">
              {{ props.row.customPeriod }}
            </span>

            <!-- Column: status -->
            <span v-else-if="props.column.field === 'invoice_status'">
              <b-badge
              style="padding:8px 15px;"
                v-if="props.row.invoice_status === 'pending'"
                class="text-capitalize biggerText"
                variant="warning"
              >
                {{ props.row.invoice_status }}
              </b-badge>

              <b-badge
              style="padding:8px 15px;color:white;"
                v-else-if="props.row.invoice_status === 'paid'"
                class="text-capitalize "
                variant="success"
              >

                {{ props.row.invoice_status }}

              </b-badge>
              <b-badge
              style="padding:8px 15px;"
                v-else
                class="text-capitalize"
                variant="danger"
              >
                {{ props.row.invoice_status }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <span v-if="props.column.field ==='open_invoice'">
              <b-button class="btn btn-success btn-sm">
                <router-link
                  class=" d-flex  align-items-center justify-content-center"
                  :to="`${props.row.customLinkDetail}`"
                >
                  <span class="text-white">
                    Open
                  </span>
                </router-link>
              </b-button>
            </span>
            <span v-if="props.column.field ==='downloading'">
              <b-button
              style="color:white;"
                variant="primary"
                size="sm"
                block
                @click="download(props.row.id)"
              >
                <span v-if="downloading.includes(props.row.id)" style="color:white;">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Downloading...
                </span>
                <span v-else style="color:white;">Download</span>
              </b-button>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, BBadge, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'
import axios from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
    BBadge,
    BButton,
  },
  data() {
    return {
      invoiceLinkText: '',
      pageLength: 25,
      downloading: [],
      pdfFilename: '',
      dir: false,
      columns: [
        {
          label: '#',
          field: 'invoiceNo',
          sortable: false,
          sortFn: this.sortFnInvoiceNo,
        },
        {
          label: 'Open Invoice',
          field: 'open_invoice',
          sortable: false,
        },
        {
          label: 'Package',
          field: 'package',
          sortable: false,
          sortFn: this.sortFnPackage,
        },
        {
          label: 'Issued On',
          field: 'issuedOn',
          sortable: true,
          sortFn: this.sortFnIssuedOn,
        },
        {
          label: 'Invoice Value',
          field: 'invoiceValue',
          sortable: true,
          sortFn: this.sortFnInvoiceValue,
        },
        {
          label: 'Period',
          field: 'period',
          sortable: true,
          sortFn: this.sortFnPeriod,
        },
        {
          label: 'Status',
          field: 'invoice_status',
          sortable: false,
        },
        {
          label: 'Download',
          field: 'downloading',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    userData() {
      return this.$store.state.auth.userData
    },
    invoices() {
      return this.$store.state.invoice.userInvoiceHistory
    },
  },
  created() {
  },
  mounted() {
    this.fetchUserHistoryInvoice()
  },
  methods: {

    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchUserHistoryInvoice() {
      this.$store.dispatch('invoice/fetchUserHistoryInvoice')
        .catch(error => { console.log(error) })
    },
    getPackage(inv) {
      let str = ''
      if (inv.type !== 4 && inv.type !== 3) str = 'Custom' // str = inv.invoice_rows[0].item ?? 'Custom'
      else if (inv.type === 4) str = 'Optional feature'
      else if (inv.overage === 'conversions') str = 'Overage Conversions'
      else str = 'Overage Clicks'

      return str
    },

    sortFnInvoiceNo(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = rowX.invoice // get x
      let value2 = rowY.invoice // get y

      // eslint-disable-next-line radix
      value1 = parseInt(value1, 10)
      // eslint-disable-next-line radix
      value2 = parseInt(value2, 10)

      // eslint-disable-next-line no-nested-ternary
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0))
    },

    sortFnPackage(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = rowX.customPackage // get x
      let value2 = rowY.customPackage // get y
      value1 = String(value1)
      value2 = String(value2)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnIssuedOn(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = Date.parse(this.filterFormatDate(rowX.issue_date)) // get x
      const value2 = Date.parse(this.filterFormatDate(rowY.issue_date)) // get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnInvoiceValue(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = rowX.invoice_value // get x
      let value2 = rowY.invoice_value // get y

      value1 = parseFloat(value1)
      value2 = parseFloat(value2)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnPeriod(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = Date.parse(this.filterFormatDate(rowX.start_date)) // get x
      const value2 = Date.parse(this.filterFormatDate(rowY.start_date)) // get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
    async download(id) {
      this.downloading.push(id)
      const url = `/api/invoices/download/${id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          this.pdfFilename = `Invoice#TM${id}.pdf`
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', this.pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = this.downloading.filter(item => item !== id)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.invoice-table {
  thead tr{
    background: #f3f2f7;
  }
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>
